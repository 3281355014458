import React from "react"
import { Box, Text, VStack, Heading } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import loadable from "@loadable/component"

import useAvailableRegion from "../../hooks/useAvailableRegion"

const AppStoreButtons = loadable(() => import("./AppStoreButtons"))

const CallToAction = () => {
  const { t } = useTranslation()
  const { loading, available } = useAvailableRegion()

  return (
    <Box width="full" backgroundColor="#E6F2FA">
      <VStack mx="auto" maxW="6xl" px="6" py="14" spacing="6">
        <Text textAlign="center" fontSize="1.2rem">
          {t("home-page.cta-section.download-today")}
        </Text>
        {available && (
          <Heading
            size="lg"
            textAlign="center"
            mb="4"
            fontWeight="400"
            color="ahuaBlue.90"
          >
            Download YaraConnect
          </Heading>
        )}

        <AppStoreButtons
          chakraStyleProps={{
            justifyContent: "center",
            spacing: { base: "4", sm: "6" },
          }}
          loading={loading}
          available={available}
        />

        {available && (
          <Text
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: t(
                "home-page.cta-section.install-guide-dangerous-unescaped"
              ),
            }}
          />
        )}
      </VStack>
    </Box>
  )
}

export default CallToAction
