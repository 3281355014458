import { useState, useEffect, useCallback } from "react"
import axios from "axios"

const requestWithCloudflareProvider = async () => {
  let { data } = await axios.get(
    process.env.GATSBY_CLOUDFLARE_IP_TRACE_ENDPOINT
  )

  data = data
    .trim()
    .split("\n")
    .reduce((obj, pair) => {
      pair = pair.split("=")
      obj[pair[0]] = pair[1]
      return obj
    }, {})

  return data
}

const availableCountries = ["IN"]

const useAvailableRegion = () => {
  const [loading, setLoading] = useState(false)
  const [available, setAvailable] = useState(true)

  const fetchIpGeolocation = useCallback(async () => {
    setLoading(true)
    try {
      const data = await requestWithCloudflareProvider()

      if (availableCountries.includes(data.loc)) {
        setAvailable(true)
      } else {
        setAvailable(false)
      }
    } catch (err) {
      setAvailable(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchIpGeolocation()
  }, [fetchIpGeolocation])

  return { loading, available }
}

export default useAvailableRegion
